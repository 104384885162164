import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServicesModule } from './services/services.module';
import { PipesModule } from './pipes/pipes.module';
import { IfaceComponentsModule } from './iface.components/iface.components.module';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './httpconfig.interceptor';
import { Downloader } from '@ionic-native/downloader/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { FileTransferObject, FileTransfer } from '@ionic-native/file-transfer/ngx';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { BackgroundFetch } from '@ionic-native/background-fetch/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ErrorHandlerModule } from './error-handler/error-handler.module';
import { environment } from 'src/environments/environment';

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot(),
		BrowserAnimationsModule,
		AppRoutingModule,
		ServicesModule,
		PipesModule,
		IfaceComponentsModule,
		HttpClientModule,
		ErrorHandlerModule.forRoot({
			dsn: "https://fbdbd7fcbf414af3964aac80291cd9a8@sentry.uco.co.il/4",
			browserTracking: true,
			tracesSampleRate: 1.0,
			release: `fixdigital.minicrm.app@${environment.version}`,
			options: {
				showDialog: false,
			}
		}),
	],
	providers: [
		StatusBar,
		SplashScreen,
		AppVersion,
		Downloader,
		MobileAccessibility,
		FileTransferObject,
		FileTransfer,
		File,
		Device,
		FirebaseX,
		BackgroundFetch,
		InAppBrowser,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
