import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import * as Sentry from "@sentry/angular"
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { WhatsappService } from './services/whatsapp.service';
import { DataService } from './services/DataServices/data.service';
import { NotificationService } from './services/notification.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(
        private dataService: DataService,
        private notificatonService: NotificationService,
        private whatsappService: WhatsappService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                const error = err.error.message || err.error.detail || err.statusText;
                return throwError(error);
            })
        )
        .pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if (event.body.responseCode == 401) {
                    var authServiceNew = new AuthService(this.dataService, null, this.notificatonService, this.whatsappService, null);
                    authServiceNew.logout();
                    this.router.navigateByUrl("/auth/login");
                } else if (event.body.responseCode == 500) {
                    if (event.body.error) {
                        const newError = new Error(event.body.error);
                        console.error(newError);
                        Sentry.withScope(scope => {
                            scope.setExtra("errorExtraData", newError);
                            scope.setExtra("handlerSource", "interceptor");
                            var eventId = Sentry.captureException(newError);
                        });
                        // Sentry.showReportDialog({
                        //     eventId: eventId,
                        // });
                    }
                }
            }
            return event;
        }));
    }
}
