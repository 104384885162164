import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ILang } from 'src/app/model/lang.interface';
import { User } from 'src/app/model/user.model';
import { AssigneesRepositoryBUT } from 'src/app/services/assignees.repository.but';
import { LangsRepository } from 'src/app/services/langs.repository';
import { UsersRepository } from 'src/app/services/users.repository';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'users-new-overlay',
    templateUrl: 'users.overlay.new.html',
    styleUrls: ["../../app.component.scss", "users.overlay.new.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class UsersNewOverlayComponent {
    public ready: boolean = false;

    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() currentLang: ILang;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Input() globalStatus: number = 0;

    public langsReady = false;
    public usersReady = false;
    public user = (new User()).init();

    constructor(
        private langsRepository: LangsRepository,
        private usersRepository: UsersRepository,
        private assigneesRepositoryBut: AssigneesRepositoryBUT,
        private appService: AppService,
    ) {
        this.ready = true;
        this.init();
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) { this.langsReady = true; } });
        this.user = (new User()).init();
        this.usersRepository.prepare().then(() => { this.usersReady = true; });
    }

    public validate(): boolean {
        var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm);
        let error = false;

        if (!this.user.email.length) {
            this.user.emailError = this.currentLang.words['enter-email'];
            error = true;
        }

        if (!regexp.test(this.user.email)) {
            this.user.emailError = this.currentLang.words['correct-email'];
            error = true;
        }

        if (!this.user.phone || !this.user.phone.length) {
            this.user.phoneError = this.currentLang.words['enter-phone'];
            error = true;
        }
        else if (!this.user.phone.match(/^(050|051|052|053|054|055|057|058|02|03|04|08|09|071|072|073|074|076|077|078|079|170|180|19[1-9]|159)-?\d{7,7}$/)) {
            this.user.phoneError = this.currentLang.words["enter-phone-validation-start"];
            error = true;
        } else {
            this.user.phoneError = "";
        }

        if (!this.user.firstName || !this.user.firstName.trim().length) {
            this.user.firstNameError = this.currentLang.words['enter-firstName'];
            error = true;
        }

        if (!this.user.firstName || !this.user.lastName.trim().length) {
            this.user.lastNameError = this.currentLang.words['enter-lastName'];
            error = true;
        }

        return !error;
    }

    public save(): void {
        if (this.validate()) {
            this.appService.showNetworkstatus("sending data...");
            this.usersRepository.sendAddUser(this.user).subscribe(res => {
                if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.usersRepository.users.push(new User().build(res.data));
                    this.close()
                    this.assigneesRepositoryBut.cacheKey = res.settingsCacheToken;
                    this.user = new User();
                    this.user.canEditAllLeads = true;
                    this.user.canViewAllLeads = true;
                    this.user.canViewAssignee = true;
                    this.user.canViewEmail = true;
                    this.user.canViewFullname = true;
                    this.user.canViewOtherData = true;
                    this.user.canViewPhone = true;
                    this.user.canViewPrice = true;
                } else if (res.responseCode == 400) {
                    var erros = res.error.split('\r\n');
                    erros.forEach((err)=> {
                        if (err.includes('Email')) this.user.emailError = err.replace('Email:', '');
                        if (err.includes('PhoneNumber')) this.user.phoneError = err.replace('PhoneNumber:', '');
                        if (err.includes('LastName:')) this.user.lastNameError = err.replace('LastName:', '');
                        if (err.includes('FirstName:')) this.user.firstNameError = err.replace('FirstName:', '');
                    })
                }
                else {
                    this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                }
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in users-new-overlay save sendAddUser: ${message}`, true);
            });
        }
    }



}
