import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { ILang } from 'src/app/model/lang.interface';
import { IProcess } from 'src/app/model/process.interface';
import { IStatus } from 'src/app/model/status.interface';
import { ISubstatus } from 'src/app/model/substatus.interface';
import { LangsRepository } from 'src/app/services/langs.repository';
import { ProcessesRepositoryBUT } from 'src/app/services/processes.repository.but';
import { SubstatusesRepository } from 'src/app/services/substatuses.repository';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'substatus-new-overlay',
    templateUrl: 'substatus.overlay.new.html',
    styleUrls: ["../../app.component.scss", "substatus.overlay.new.scss"],
    encapsulation: ViewEncapsulation.None,
})

export class SubStatusNewOverlayComponent {
    public ready: boolean = false;

    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() currentLang: ILang;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Input() globalStatus: number = 0;
    @Input() processId: number = 0;

    public langsReady = false;
    public substatusesReady = false;
    public processesReady = false;
    public substatus: ISubstatus = { title: "", statusId: null, template: "", position: 0 };
    public advanced = false;

    constructor(
        protected appService: AppService,
        protected langsRepository: LangsRepository,
        protected substatusesRepository: SubstatusesRepository,
        protected processesRepository: ProcessesRepositoryBUT,
        private loadingCtrl: LoadingController,
    ) {
        this.ready = true;
        this.init();
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) { this.langsReady = true; } });
        this.processesRepository.prepare().then(() => { this.processesReady = true; });
        this.substatus = { title: "", statusId: null, template: "", position: 0 };
        this.substatusesRepository.prepare().then(() => { this.substatusesReady = true; });
    }

    get statuses(): IStatus[] {
        var process = this.processesRepository.processes[0];
        return process ? process.statuses : [];
    }

    public validate(): boolean {
        let err = false;

        if (!this.substatus.title.trim().length) {
            this.substatus.titleError = this.currentLang.words['enter-status'];
            err = true;
        } else if(this.substatus.title.trim().length > 250){
            this.substatus.titleError = this.currentLang.words['substatus-limit-error'];
            err = true;
        } else {
            this.substatus.titleError = "";
        }

        if(!this.substatus.position){
            this.substatus.positionError = this.currentLang.words['substatus-position-error'];
            err = true;
        }

        if (this.substatus.position && this.substatus.position.toString().length > 10) {
            this.substatus.positionError = this.currentLang.words['substatus-position-error'];
            err = true;
        }

        if (!this.substatus.statusId) {
            this.substatus.statusIdError = this.currentLang.words['enter-status-stage'];
            err = true;
        } else {
            this.substatus.statusIdError = "";
        }

        return !err;
    }

    public async save(): Promise<void> {
        this.substatus.template = this.appService.formatText(this.substatus.template);
        this.substatus.statusId = this.globalStatus;
        this.substatus.processId = this.processId;
        if (this.validate()) {
            this.appService.showNetworkstatus("sending data...");
            
            if(this.substatus.processId == null){
                this.substatus.processId = this.processesRepository.processes[0].id;
            }
            if(this.substatus.processId === 0) {
                const defaultProcesses = this.processesRepository.processes.filter((i: IProcess) => i.isDefault);
                if (defaultProcesses.length > 0) {
                    this.substatus.processId = defaultProcesses[0].id;
                }
            }
            const loading = await this.loadingCtrl.create({
                message: this.currentLang.words["wait"],
            });
            loading.present();
            this.substatusesRepository.sendAddSubstatus(this.substatus).subscribe(res => {
                if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.substatusesRepository.substatuses.push(res.data);
                    this.processesRepository.load();
                    this.substatusesRepository.load();
                    this.substatusesRepository.sort();
                    this.close();
                    this.substatus = { title: "", statusId: null, template: "", position: 0 };
                } else if (res.responseCode === 400) {
                    this.substatus.titleError = this.currentLang.words['status-exist'];
                } else {
                    this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                }
                loading.dismiss();
            }, err => {
                loading.dismiss();
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in substatus-new-overlay save sendAddSubstatus: ${message}`, true);
            });
        }
    }
}
